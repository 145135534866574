import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

/** Async thunk to post registration info */
export const uploadFile = createAsyncThunk(
  "upload/uploadFile",
  async (payload, { rejectWithValue }) => {
    try {
      const persistedStateString = localStorage.getItem("persist:root");
      const persistedState = JSON.parse(persistedStateString);
      const loginStateString = persistedState.login;
      const loginState = JSON.parse(loginStateString);
      const token = loginState.verifyInfo.token;
      const formData = new FormData();
      formData.append("file", payload.file);
      formData.append("file", payload.additionalArtifactData.length);
      formData.append("file", payload.additionalArtifactData.breadth);
      formData.append("file", payload.additionalArtifactData.unit);
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
        onUploadProgress: (progressEvent) => {
          // Report upload progress (e.g., to show a progress bar)
          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
          console.log(`Upload Progress: ${percentCompleted}%`);
        },
      };

      const response = await axios.post(
        `http://localhost:4000/api/artifact/new`,
        formData,
        config
      );
      return response.data; // Directly return the response data
    } catch (err) {
      console.error("API Error:", err);
      return rejectWithValue(err.response?.data || "Something went wrong");
    }
  }
);

/** Async thunk to post login info */
export const allFileData = createAsyncThunk(
  "upload/allFileData",
  async (_, { rejectWithValue }) => {
    try {
      const persistedStateString = localStorage.getItem("persist:root");
      const persistedState = JSON.parse(persistedStateString);
      const loginStateString = persistedState.login;
      const loginState = JSON.parse(loginStateString);
      const token = loginState.verifyInfo.token;
      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };
      const response = await axios.get(
        `https://api.vrchitect.rzr.app/api/artifact/user/all`,
        config
      );
      return response.data; // Directly return the response data
    } catch (err) {
      console.error("API Error:", err);
      return rejectWithValue(err.response?.data || "Something went wrong");
    }
  }
);

/** Initial state */
const initialState = {
  upload: null,
  uploadLoading: false,
  uploadError: "",
  allFile: null,
  allFileLoading: false,
  allFileError: "",
};

/** Redux Slice */
const uploadSlice = createSlice({
  name: "upload",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Registration cases
    builder
      .addCase(uploadFile.pending, (state) => {
        state.uploadLoading = true;
        state.uploadError = "";
      })
      .addCase(uploadFile.fulfilled, (state, action) => {
        state.upload = action.payload || null;
        state.uploadLoading = false;
        state.uploadError = "";
      })
      .addCase(uploadFile.rejected, (state, action) => {
        state.uploadLoading = false;
        state.uploadError = action.payload || "Failed to register";
      });

    // Login cases
    builder
      .addCase(allFileData.pending, (state) => {
        state.allFileLoading = true;
        state.allFileError = "";
      })
      .addCase(allFileData.fulfilled, (state, action) => {
        state.allFile = action.payload || null; // Store the logged-in user data
        state.allFileLoading = false;
        state.allFileError = "";
      })
      .addCase(allFileData.rejected, (state, action) => {
        state.allFileLoading = false;
        state.allFileError = action.payload || "Failed to login";
      });
  },
});

/** Export actions and reducer */
export default uploadSlice.reducer;
